import { firebase, firebaseLogs } from './firebaseConnection';
import 'firebase/firestore';
import { getShowUser } from './api';

interface IGenericLog {
  userId: string;
  action: string;
  sellerId?: string;
}

interface ICreateNewUserLog {
  userId: string;
  newUserEmail: string;
  sellerId?: string;
}

interface IManageExistingUserLog {
  userId: string;
  sellerId?: string;
  action: string;
  status: string | null;
  modifiedUserEmail: string | null;
  modifiedUserId: string | string[];
}

interface ICreateNewSubjectLog {
  userId: string;
  sellerId?: string;
  newSubjectName: string;
}

interface IManageSubjectsLog {
  userId: string;
  sellerId?: string;
  action: string;
  itemId: string | null;
  itemContentId: string | null;
  subjectId: string;
}

interface ICreateNewCourseLog {
  userId: string;
  sellerId?: string;
  newCourseName: string;
}

interface IManageCoursesLog {
  userId: string;
  sellerId?: string;
  action: string;
  courseId: string;
}

interface IManageDelagationUnitsLog {
  userId: string;
  action: string;
  curatorId: string;
  reviewerId: string;
  unityId: string | string[];
}

interface IManageUnitsLog {
  userId: string;
  action: string;
  status: string;
  unityId: string | string[];
}

interface IManagePlansLog {
  userId: string;
  action: string;
  planId: string | null;
  planName: string;
}

interface IManagePlanPriceLog {
  userId: string;
  action: string;
  providerId: string;
  planId: string;
  value: string;
}

interface IManageProvidersLog {
  userId: string;
  action: string;
  status: string | null;
  providerId: string | string[] | null;
}

interface IManageSellersLog {
  userId: string;
  action: string;
  status: string | null;
  sellerId: string | null;
}

interface IDataFilterLogs {
  user_id?: string;
  start_date: string;
  end_date: string;
  log_type?: string;
  availableUsers?: string[];
  availableUser?: string[] | string;
}

interface IOptionsProps {
  label: string;
  value: string | number;
  status?: string;
}

interface ILogActions {
  userName?: string;
  action: string;
  createdAt: string;
}

interface IUsers {
  id: string;
  name: string;
  email: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface ITimeLogProps {
  t: {
    seconds: number;
    nanoseconds: number;
  };
}

interface ILogsProps {
  action: string;
  createdAt: ITimeLogProps;
  userId: string;
}

interface IManageUnitsFromEditorLog {
  action: string;
  userId: string;
  unityId: string | string[] | null;
}

const dataBaseLogs = firebaseLogs.firestore();
const Timestamp = firebase.firestore.FieldValue.serverTimestamp();

let logCollection =
  process.env.REACT_APP_ENV === 'staging' ? 'logs-staging' : 'logs';

if (process.env.NODE_ENV === 'development') {
  logCollection = 'logs-staging';
}

// FUNCTIONS PARA TRATAMENTO DE DADOS
export const getLogActionTranslation = (data): string => {
  let action = '';

  switch (data) {
    case 'logged':
      action = 'Efetuou Login';
      break;
    case 'logged_out':
      action = 'Efetuou Logout';
      break;
    case 'updated_provider_status':
      action = 'Alterou o status do Fornecedor';
      break;
    case 'updated_condition_provider':
      action = 'Alterou a condição do Fornecedor';
      break;
    case 'update_user_status':
      action = 'Alterou o Status do Usuário';
      break;
    case 'update_unit_status':
      action = 'Alterou o Status da Unidade';
      break;
    case 'update_subject_name':
      action = 'Alterou o Nome da Disciplina';
      break;
    case 'removed_subject':
      action = 'Removeu uma Disciplina';
      break;
    case 'added_item_to_cart':
      action = 'Adicionou Item ao Carrinho';
      break;
    case 'updated_seller_status':
      action = 'Alterou o Status do Vendedor';
      break;
    case 'generated_lms_key':
      action = 'Gerou Nova LMS Key';
      break;
    case 'updated_profile':
      action = 'Atualizou o Perfil';
      break;
    case 'removed_course':
      action = 'Removeu um Curso';
      break;
    case 'payment_requested':
      action = 'Realizou o Pedido de Dados de Pagamento';
      break;
    case 'imported_subject':
      action = 'Importou Disciplina';
      break;
    case 'imported_course':
      action = 'Importou Curso';
      break;
    case 'edit_user':
      action = 'Editou um Usuário';
      break;
    case 'updated_course':
      action = 'Alterou um Curso';
      break;
    case 'added_item_to_subject':
      action = 'Adicionou uma Unidade à Disciplina';
      break;
    case 'updated_delegate_unity':
      action = 'Alterou a Delegação da Unidade';
      break;
    case 'delegate_unity':
      action = 'Delegou uma Unidade';
      break;

    default:
      action = 'Outro';
  }
  return action;
};

export const logTypes = async (): Promise<IOptionsProps[]> => {
  const types: IOptionsProps[] = [{ label: 'Todos', value: 'all' }];
  const allLogsTypes = await dataBaseLogs
    .collection('log-types')
    .doc('aQgWRdu1lDe435bKDpdw')
    .get()
    .then(doc => {
      return doc.data();
    });
  if (allLogsTypes) {
    allLogsTypes.type.forEach(type => {
      types.push({ label: getLogActionTranslation(type), value: type });
    });
  }

  return types;
};

export const convertCreatedAtLog = (data): string => {
  const convertedDate = new Date(data.seconds * 1000).toLocaleString();

  return convertedDate;
};

export const convertEndDateProcessed = async (EndDate): Promise<string> => {
  const EndDateDivided = EndDate.split('-');
  const dayEndDate =
    +EndDateDivided[2] < 9
      ? `0${+EndDateDivided[2] + 1}`
      : +EndDateDivided[2] + 1;

  const EndDateProcessed =
    await `${EndDateDivided[0]}-${EndDateDivided[1]}-${dayEndDate}T23:59:59.000Z`;
  return EndDateProcessed;
};

export const separate = (itens): string[] => {
  const arrayUsersAvailable: any = [];
  for (let i = 0; i < itens.length; i += 10) {
    arrayUsersAvailable.push(itens.slice(i, i + 10));
  }
  return arrayUsersAvailable;
};

// FUNCTIONS PARA OBTENÇÃO DE DADOS
export const saveGenericLog = (data: IGenericLog): void => {
  const { userId, sellerId, action } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action,
    createdAt: Timestamp,
  });
};

export const saveCreateNewUserLog = (data: ICreateNewUserLog): void => {
  const { userId, newUserEmail, sellerId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action: 'created_new_user',
    newUserEmail,
    createdAt: Timestamp,
  });
};

export const saveManageUserLog = (data: IManageExistingUserLog): void => {
  const {
    userId,
    sellerId,
    action,
    status,
    modifiedUserEmail,
    modifiedUserId,
  } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action,
    status,
    modifiedUserEmail,
    modifiedUserId,
    createdAt: Timestamp,
  });
};

export const saveCreateNewSubjectLog = (data: ICreateNewSubjectLog): void => {
  const { userId, sellerId, newSubjectName } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action: 'created_new_subject',
    newSubjectName,
    createdAt: Timestamp,
  });
};

export const saveManageSubjectsLog = (data: IManageSubjectsLog): void => {
  const { userId, sellerId, action, itemId, itemContentId, subjectId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action,
    itemId,
    itemContentId,
    subjectId,
    createdAt: Timestamp,
  });
};

export const saveCreateNewCouseLog = (data: ICreateNewCourseLog): void => {
  const { userId, sellerId, newCourseName } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action: 'created_new_course',
    newCourseName,
    createdAt: Timestamp,
  });
};

export const saveManageCoursesLog = (data: IManageCoursesLog): void => {
  const { userId, sellerId, action, courseId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    sellerId,
    action,
    courseId,
    createdAt: Timestamp,
  });
};

export const saveManageDelegationUnitsLog = (
  data: IManageDelagationUnitsLog,
): void => {
  const { userId, action, unityId, curatorId, reviewerId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    unityId,
    curatorId,
    reviewerId,
    createdAt: Timestamp,
  });
};

export const saveManageUnitsLog = (data: IManageUnitsLog): void => {
  const { userId, action, status, unityId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    status,
    unityId,
    createdAt: Timestamp,
  });
};

export const saveManagePlansLog = (data: IManagePlansLog): void => {
  const { userId, action, planId, planName } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    planId,
    planName,
    createdAt: Timestamp,
  });
};

export const saveManagePlanPriceLog = (data: IManagePlanPriceLog): void => {
  const { userId, action, providerId, planId, value } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    providerId,
    planId,
    value,
    createdAt: Timestamp,
  });
};

export const saveManageProvidersLog = (data: IManageProvidersLog): void => {
  const { userId, action, status, providerId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    status,
    providerId,
    createdAt: Timestamp,
  });
};

export const saveManageSellersLog = (data: IManageSellersLog): void => {
  const { userId, action, status, sellerId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    status,
    sellerId,
    createdAt: Timestamp,
  });
};

export const saveManageUnitsFromEditorLog = (
  data: IManageUnitsFromEditorLog,
): void => {
  const { userId, action, unityId } = data;

  dataBaseLogs.collection(logCollection).add({
    userId,
    action,
    unityId,
    createdAt: Timestamp,
  });
};

export const getLogsByFilter = async (
  data: IDataFilterLogs,
): Promise<ILogsProps[]> => {
  const { start_date, end_date, user_id, log_type } = data;
  const dataFound: any = [];

  const EndDateProcessed = await convertEndDateProcessed(end_date);

  let logsFound: any = '';
  if (log_type && log_type !== 'all') {
    logsFound = await dataBaseLogs
      .collection(logCollection)
      .where('userId', '==', user_id)
      .where('createdAt', '>=', new Date(start_date))
      .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
      .where('action', '==', log_type)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async doc => {
          await dataFound.push(doc.data());
        });

        return dataFound;
      })
      .catch(error => {
        throw new Error(`Error getting documents: ${error}`);
      });
  } else {
    logsFound = await dataBaseLogs
      .collection(logCollection)
      .where('userId', '==', user_id)
      .where('createdAt', '>=', new Date(start_date))
      .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
      .get()
      .then(async querySnapshot => {
        querySnapshot.forEach(async doc => {
          await dataFound.push(doc.data());
        });

        return Promise.all(await dataFound);
      })
      .catch(error => {
        throw new Error(`Error getting documents: ${error}`);
      });
  }

  return Promise.all(await logsFound);
};

export const getLogsByFilterWithoutUser = async (
  data: IDataFilterLogs,
): Promise<ILogsProps[]> => {
  const { start_date, end_date, log_type, availableUsers, availableUser } =
    data;
  const dataFound: any = [];
  const usersToFind =
    availableUsers === undefined ? availableUser : availableUsers;

  const EndDateProcessed = await convertEndDateProcessed(end_date);

  let logsFound: any = '';
  if (log_type && log_type !== 'all') {
    logsFound = dataBaseLogs
      .collection(logCollection)
      .where('userId', 'in', usersToFind)
      .where('createdAt', '>=', new Date(start_date))
      .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
      .where('action', '==', log_type)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          dataFound.push(doc.data());
        });

        return dataFound;
      })
      .catch(error => {
        throw new Error(`Error getting documents: ${error}`);
      });
  } else {
    logsFound = dataBaseLogs
      .collection(logCollection)
      .where('userId', 'in', usersToFind)
      .where('createdAt', '>=', new Date(start_date))
      .where('createdAt', '<=', new Date(`${EndDateProcessed}`))
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          dataFound.push(doc.data());
        });

        return dataFound;
      })
      .catch(error => {
        throw new Error(`Error getting documents: ${error}`);
      });
  }

  return logsFound;
};

export const getUserPropById = async (userId): Promise<IUsers> => {
  let user;
  try {
    const { data: userFind } = await getShowUser(userId);
    user = userFind;
  } catch (error) {
    user = { name: 'Não encontrado' };
  }

  return user;
};

export const getLogsToReport = async (
  data: IDataFilterLogs,
): Promise<ILogActions[]> => {
  const { start_date, end_date, user_id, log_type } = data;
  const usersArray: any = [];
  let userName = '';
  const logsFound = await getLogsByFilter({
    start_date,
    end_date,
    user_id,
    log_type,
  });

  const resultLogsFound = await logsFound.map(async log => {
    const userSearch = await usersArray.find(
      element => element.userId === log.userId,
    );

    if (userSearch) {
      userName = userSearch.name;
    } else {
      const user = await getUserPropById(log.userId);
      usersArray.push(user);
      userName = user.name;
    }
    const resultLogsProcessed = {
      userName,
      action: getLogActionTranslation(log.action),
      createdAt: convertCreatedAtLog(log.createdAt),
    };
    return resultLogsProcessed;
  });

  return Promise.all(await resultLogsFound);
};

export const getLogsToReportWithoutUser = async (
  data: IDataFilterLogs,
  availableUsers: string[],
): Promise<ILogActions[]> => {
  const { start_date, end_date, log_type } = data;
  const dataLogsProcessed: ILogActions[] = [];
  let logsFounded: any = '';

  if (availableUsers.length <= 10) {
    const logsFound = await getLogsByFilterWithoutUser({
      start_date,
      end_date,
      log_type,
      availableUsers,
    });

    logsFounded = logsFound;
  }

  if (availableUsers.length >= 11) {
    const arrayAvailableUsers = await separate(availableUsers);

    const usersResult = await arrayAvailableUsers.map(async availableUser => {
      const logsFound = await getLogsByFilterWithoutUser({
        start_date,
        end_date,
        log_type,
        availableUser,
      });
      return Promise.all(logsFound);
    });

    logsFounded = await Promise.all(usersResult);
    logsFounded.forEach(async logs => {
      logs.forEach(async log => {
        const usersArray: any = [];
        let userName = '';
        const userSearch = usersArray.find(
          element => element.userId === log.userId,
        );

        if (userSearch) {
          userName = userSearch.name;
        } else {
          const user = await getUserPropById(log.userId);
          usersArray.push(user);
          userName = user.name;
        }

        dataLogsProcessed.push({
          userName,
          action: getLogActionTranslation(log.action),
          createdAt: convertCreatedAtLog(log.createdAt),
        });
      });
    });

    return dataLogsProcessed;
  }

  logsFounded.forEach(async log => {
    const usersArray: any = [];
    let userName = '';
    const userSearch = usersArray.find(
      element => element.userId === log.userId,
    );

    if (userSearch) {
      userName = userSearch.name;
    } else {
      const user = await getUserPropById(log.userId);
      usersArray.push(user);
      userName = user.name;
    }

    dataLogsProcessed.push({
      userName,
      action: getLogActionTranslation(log.action),
      createdAt: convertCreatedAtLog(log.createdAt),
    });
  });

  return dataLogsProcessed;
};
